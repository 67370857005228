import React from 'react'

const ServicesCard = () => {
  return (
    <>
        <div className="servicesCard">
            <div className="servicesImg">
                Logo
            </div>
            <div className="serviceName">
                <h3>Area</h3>
            </div>
            <div className="serviceDisc">
                <p>Lorem ipsum dolor sit amet. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magni rem unde necessitatibus libero voluptatum cumque accusamus a omnis sint soluta sapiente nemo, nulla natus. Eos recusandae laboriosam omnis tempore numquam repellat temporibus, soluta illo quae. Laudantium, ex amet placeat voluptatum quos odit nisi dolorum possimus. Unde suscipit possimus dicta nulla!</p>
            </div>
        </div>
    </>
  )
}

export default ServicesCard