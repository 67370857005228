import React from 'react'
import './AdminDashBoard.css'
const AdminDashBoard = () => {
  return (
    <>
        <div className='adminDashboard'>
            <h2>Name</h2>
            <p>Game Title</p>
            <p>Age</p>
            <p>Gender</p>
            <p>Category</p>
            <p>subcategory</p>
            <p>benefitsofplaying</p>
            <p>itemsrequied</p>
            <p>url</p>
            <p>score</p>
            <p>level</p>
        </div>
    </>
  )
}

export default AdminDashBoard