import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Assets from "../Images/index";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";

const Home = () => {
  const companyName = "Curiosense Innovations";
  const AboutCompany =
    "Curiosense Innovations prioritizes a play-centric, curiosity-driven approach for resilient minds. Emphasizing holistic development and mental well-being, we collaborate with parents, teachers, experts and schools to form a comprehensive learning ecosystem. With innovative tools, we prepare children not just for today but also for future opportunities, focusing on skill development and lifelong success.";

  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <>
      <div className="home-sec">
        <div className="home-cnt">
          <h2>
            Welcome To <br /> <span className="subHeading">{companyName}</span>
          </h2>
          <p>{AboutCompany}</p>
          <Link to="/about">
            <button className="buy-button home-btn"> Explore More →</button>
          </Link>
        </div>
        <div
          className="home-img"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="100"
          data-aos-offset="1"
        >
          <img className="animation" src={Assets?.assets6} />
        </div>
      </div>
    </>
  );
};

export default Home;
