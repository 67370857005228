import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import AboutData from "../Data/AboutData.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import "./AboutUs.css";
import image3 from "../Images/Assets/assets2.jpeg";

export const AboutUs = () => {

  useEffect(() => {
    AOS.init({});
  }, []);

  const aboutHeaders = "CURIOSENSE INNOVATIONS PVT LTD";

  const aboutDiscription = (
    <p>
      At CURIOSENSE INNOVATIONS PVT LTD, we believe in the transformative power
      of curiosity and play-based learning to shape resilient minds. Our mission
      is rooted in fostering not just academic growth, but also nurturing the
      mental well-being of children.
    </p>
  );
  return (
    <section className="aboutus">
      <div className="title">About Us</div>
      <div className="aboutus-sec">
        <div className="about-img">
          <img
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
            src={image3}
          />
        </div>
        <div className="aboutMainHeader">
          <p className="aboutMainPara"> {aboutDiscription} </p>
          <h2 className="aboutHeader">Why Choose Us ?</h2>
          <div className="correctionWrapper">
            <FontAwesomeIcon icon={faCheck} className="correctSign" />
            <span>Our Approach: Curiosity Unleashed</span>
          </div>
          <div className="correctionWrapper">
            <FontAwesomeIcon icon={faCheck} className="correctSign" />
            <span>Mind Matters: Prioritizing Mental Health</span>
          </div>
          {/* <div className="correctionWrapper">
            <FontAwesomeIcon icon={faCheck} className="correctSign" />
            <span>Partners in Progress: Parents, Teachers, and Schools</span>
          </div> */}
          <div className="correctionWrapper">
            <FontAwesomeIcon icon={faCheck} className="correctSign" />
            <span>Innovative Tools for Tomorrow's Skills</span>
          </div>
          <div className="correctionWrapper">
            <FontAwesomeIcon icon={faCheck} className="correctSign" />
            <span>Skills for Tomorrow: Empowering Futures</span>
          </div>
          <div className="correctionWrapper">
            <FontAwesomeIcon icon={faCheck} className="correctSign" />
            <span>Transforming the way of learning</span>
          </div>
          <div className="correctionWrapper">
            <FontAwesomeIcon icon={faCheck} className="correctSign" />
            <span>Introducing vedic methods</span>
          </div>
          <div className="correctionWrapper">
            <FontAwesomeIcon icon={faCheck} className="correctSign" />
            <span>Creating a better tomorrow</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
