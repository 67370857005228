import React, { Suspense } from "react";
// import ProductCard from "../Components/ProductCard";
import "./Product.css";

import Product1 from "../Images/Products/Product1.jpeg";
import Product2 from "../Images/Products/Product2.jpeg";
import Product3 from "../Images/Products/Product3.jpeg";
import Product4 from "../Images/Products/Product4.jpeg";

const ProductCard = React.lazy(() => import("../Components/ProductCard"));
const Product = () => {
  return (
    <div className="product-sec">
      <div className="title">Our Products</div>
      <div className="productSec">
        <Suspense fallback={<p>Loading...</p>}>
          <ProductCard
            name="CurioBook"
            bio="Unlock the world of kindergarten basics with our patented device designed for enjoyable learning experiences. Assess learning milestones and identify potential neurological disorders seamlessly."
            price="₹ 1999/-"
            imageUrl={Product1}
            buttonSec="Buy Now"
          />
          <ProductCard
            name="CurioFit"
            bio="CurioFit develops physical games for children, designed collaboratively by child psychologists, developmental pediatricians, and nursery teachers. These activities aim for the holistic development of children, fostering physical, cognitive, and social growth"
            imageUrl={Product2}
            buttonSec="GAME CREATOR'S ACCOUNT"
          />
          <ProductCard
            name="CurioCards"
            bio="CurioCards transforms learning into a joyful experience for children. Through engaging stories, vibrant visuals, interactive games, and creative spaces, these cards seamlessly align with school subjects, ensuring an enjoyable and effective educational journey."
            imageUrl={Product3}
            buttonSec="Buy Now"
          />
          <ProductCard
            name="CurioTherapy"
            bio="CurioTherapy provides home-based cognitive retraining programs for children with developmental disorders, offering convenience, empowerment, and progress tracking. Developed with input from psychologists, child development therapists, and developmental pediatricians, it benefits parents, caregivers, and children with tailored effective therapy."
            imageUrl={Product4}
            buttonSec="Book Now"
          />
        </Suspense>
      </div>
    </div>
  );
};

export default Product;
