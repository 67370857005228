import React from 'react'
import ServicesCard from '../Components/ServicesCard'


const Services = () => {
  return (
    <div className='ServicesMain'>
        <ServicesCard />
    </div>
  )
}

export default Services