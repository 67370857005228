import PropagateLoader from "react-spinners/PropagateLoader";
import companyLogo from "../Images/Logo.jpeg"
import "./LoadingPage.css"


const LoadingPage = () => {
  return (
    <div className="sweet-loading">
      <img src={companyLogo} alt="logo" />
      <PropagateLoader color="rgba(232, 26, 34, 1)" />
    </div>
  )
}

export default LoadingPage